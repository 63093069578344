import { forwardRef, useCallback, useEffect, useImperativeHandle, useRef } from 'react';
import tinymce, { Editor as TinyMCEditor } from 'tinymce';
import { Editor } from '@tinymce/tinymce-react';
import { Sheet } from '@mui/joy';
import { Skeleton } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';

import { LanguageCode } from '~/common/enums';
import { Nullable } from '~/common/types';
import { RichTextEditorProps } from './types';

import { tinyMCEToolbarSections } from '@/shared/utils/constants';
import useObjState from '@/shared/hooks/useObjState';

const BASE_PLUGINS = [
  'advlist',
  'autolink',
  'lists',
  'link',
  'image',
  'charmap',
  'preview',
  'anchor',
  'searchreplace',
  'visualblocks',
  'code',
  'fullscreen',
  'insertdatetime',
  'media',
  'table',
  'powerpaste',
  'tinymcespellchecker',
];

const CONTENT_LANGUAGES = [
  { code: 'en_US', title: 'English (US)' },
  { code: 'en_UK', title: 'English (UK)' },
  { code: 'fr', title: 'Français' },
  { code: 'nl', title: 'Nederlands' },
];

const { CLEAR, TABLE_PROPS, TEXT_DECORATION, UNDO_REDO, UNDO_REDO_CLEAR } = tinyMCEToolbarSections;

const DEFAULT_TOOLBAR_ITEMS = [UNDO_REDO, TEXT_DECORATION];
const DEFAULT_TOOLBAR_ITEMS_CLEARABLE = [UNDO_REDO_CLEAR, TEXT_DECORATION];

const SPELLCHECKER_LANGUAGE_MAP = {
  [LanguageCode.EN]: 'en_UK',
  [LanguageCode.FR]: 'fr',
  [LanguageCode.NL]: 'nl',
};

const SPELLCHECKER_LANGUAGE_OPTIONS =
  'English (US)=en_US, English (UK)=en_UK, Français=fr, Nederlands=nl';

const getCurrentSpellCheckerLanguage = (languageCode: string) =>
  Object.hasOwn(SPELLCHECKER_LANGUAGE_MAP, languageCode)
    ? SPELLCHECKER_LANGUAGE_MAP[languageCode as keyof typeof SPELLCHECKER_LANGUAGE_MAP]
    : SPELLCHECKER_LANGUAGE_MAP[LanguageCode.EN];

const RichTextEditor = forwardRef<TinyMCEditor, RichTextEditorProps>(
  (
    {
      callback,
      clearable = false,
      disabled = false,
      deferLoad,
      disableSpellChecker = false,
      enableTags = false,
      height = 500,
      initialValue,
      onBlur,
      onDirty,
      readOnly = false,
      removeLineBreaks = false,
      showMenubar = false,
      setup,
      tags = [],
      toolbarItems,
      value,
      ...initProps
    },
    forwardedRef,
  ) => {
    const editorRef = useRef<Nullable<TinyMCEditor>>(null);

    const flags = useObjState({
      hasBeenFocussed: false,
      initialized: false,
    });

    const { i18n, t } = useTranslation();

    const toolbarSections = (() => {
      if (toolbarItems) return toolbarItems;

      return clearable ? DEFAULT_TOOLBAR_ITEMS_CLEARABLE : DEFAULT_TOOLBAR_ITEMS;
    })();

    const checkForInvalidTags = useCallback(() => {
      if (!enableTags || !editorRef.current) return;

      const body = editorRef.current.getBody();
      const tagsInBody = body.getElementsByClassName('mce-mergetag');

      for (let i = 0; i < tagsInBody.length; i += 1) {
        const nodes = tagsInBody[i].childNodes;

        for (let j = 0; j < nodes.length; j += 1) {
          if (nodes[j].nodeType === 3) {
            const tag = nodes[j].nodeValue;

            if (!tags.some((tMenuItem) => tMenuItem.menu.some((tItem) => tItem.value === tag))) {
              tagsInBody[i].classList.add('invalid');
            } else {
              tagsInBody[i].classList.remove('invalid');
            }
          }
        }
      }
    }, [enableTags, tags]);

    const clearEditor = () => {
      if (!editorRef.current) return;

      editorRef.current.setContent('');
    };

    const getPlugins = () => {
      const plugins = BASE_PLUGINS;

      if (enableTags && tags.length) {
        plugins.push('mergetags');
      }

      return plugins;
    };

    const getToolbar = () => {
      if (disabled) return '';

      let joinedSections = toolbarSections.join(' | ');

      if (clearable && !joinedSections.includes(CLEAR))
        joinedSections = [joinedSections, CLEAR].join(' | ');

      return joinedSections;
    };

    const handleFocus = () => {
      if (readOnly) return;

      flags.set('hasBeenFocussed', true);
    };

    const handleInit = (editor: TinyMCEditor) => {
      flags.set('initialized', true);

      editorRef.current = editor;

      checkForInvalidTags();
    };

    const parseBody = (htmlString: string) => {
      const nodes = tinymce.html.DomParser({ sanitize: true, validate: true }).parse(htmlString);

      // note: this will not include any tinymce skin class names, so this won't look the same as rendering in the editor itself
      return tinymce.html.Serializer().serialize(nodes);
    };

    const getSrcDoc = (bodyString: string) => `
      <html>
        <head>
          <style>
            body {
              font-family: 'Roboto', 'Arial', 'sans-serif';
              font-size: 1rem;
              margin: 1rem;
            }
          </style>
        </head>
        <body>
          ${parseBody(bodyString)}
        </body>
      </html>
    `;

    useImperativeHandle(forwardedRef, () => editorRef.current as TinyMCEditor);

    useEffect(() => checkForInvalidTags(), [checkForInvalidTags]);

    const showPlaceholder = readOnly || (deferLoad && !flags.hasBeenFocussed);

    return (
      <div className="relative h-full">
        {!flags.initialized && !deferLoad && !readOnly && (
          <Skeleton
            height={height}
            width="100%"
            variant="rounded"
            style={{ position: 'absolute', zIndex: 5 }}
          />
        )}
        {showPlaceholder ? (
          <Sheet
            tabIndex={0}
            variant="outlined"
            onBlur={handleFocus}
            onFocus={handleFocus}
            sx={(theme) => ({
              borderRadius: theme.vars.radius.md,
              height,
              width: '100%',
            })}
          >
            <iframe
              className="pointer-events-none h-full w-full"
              title={`tinymce_placeholder_${uuidv4()}`}
              srcDoc={getSrcDoc(initialValue ?? value ?? '')}
            />
          </Sheet>
        ) : (
          <Editor
            disabled={disabled}
            init={{
              branding: false,
              browser_spellcheck: false,
              content_css: ['material-outline', '/styles/tiny.css'],
              content_langs: CONTENT_LANGUAGES,
              element_format: 'xhtml',
              height,
              icons: 'material',
              language: i18n.language,
              menubar: showMenubar,
              mergetags_list: tags,
              mergetags_prefix: '{{',
              mergetags_suffix: '}}',
              remove_linebreaks: removeLineBreaks,
              setup: (editor) => {
                if (clearable) {
                  editor.ui.registry.addButton('clear', {
                    icon: 'remove',
                    onAction: clearEditor,
                    tooltip: t('clear'),
                  });
                }

                setup?.(editor);
              },
              skin: 'material-outline',
              spellchecker_active: !disableSpellChecker,
              spellchecker_language: getCurrentSpellCheckerLanguage(i18n.language),
              spellchecker_languages: SPELLCHECKER_LANGUAGE_OPTIONS,
              table_toolbar: TABLE_PROPS,
              toolbar1: getToolbar(),
              ...initProps,
            }}
            initialValue={initialValue}
            plugins={getPlugins()}
            value={value}
            onBlur={onBlur}
            onDirty={() => onDirty?.(true)}
            onEditorChange={callback}
            onInit={(_, editor) => handleInit(editor)}
          />
        )}
      </div>
    );
  },
);

export default RichTextEditor;
