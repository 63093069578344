import { lowerFirst } from 'lodash';
import { TFunction } from 'i18next';

import { InvoiceCreationMode } from '~/common/enums';

type Props = {
  rowData: { invoiceCreationMode: InvoiceCreationMode };
  t: TFunction;
};

const InvoiceCreationModeCustomCell: React.FC<Props> = ({ rowData: { invoiceCreationMode }, t }) =>
  t(`_syndicManagement.invoiceCreationMode.${lowerFirst(invoiceCreationMode)}`);

export default InvoiceCreationModeCustomCell;
