import { memo } from 'react';

import { formatAsCurrency } from '@/shared/utils/helpers';
import { Nullable } from '~/common/types';

type Props = {
  value: Nullable<number>;
};

const CurrencyCell: React.FC<Props> = ({ value }) => {
  if (value == null || Number.isNaN(Number(value))) {
    return '-';
  }
  return formatAsCurrency(value, true);
};

export default memo(CurrencyCell);
