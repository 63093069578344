import { Alert, IconButton } from '@mui/joy';
import {
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  Title,
  Tooltip,
} from 'chart.js';
import { useEffect, useMemo, useState } from 'react';
import classNames from 'classnames';
import { useNavigate } from 'react-router';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { OpenIcon, WarningIcon } from '@/shared/icons/Icons';
import DashboardHeader from './Widgets/DashboardHeader';
import DashboardSettings from './dashboard.types';
import DashboardTitle from './Widgets/DashboardTitle';
import KpcFullPage from '@/shared/components/2.0/layout/KpcFullPage';
import { localStorageKeys } from '@/shared/utils/constants';
import OpenTasksByTaskTypeChart from './Widgets/OpenTasksByTaskTypeChart';
import OpenTaskTable from './Widgets/OpenTasksTable';
import { RootState } from '@/redux';
import { trpc } from '@/config/trpc';
import { useUserRole } from '@/queries';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);
const defaultSettings = {
  columnCount: 2,
  showStatistics: true,
  showTasksChart: true,
  showTasksTable: true,
};
const Dashboard = () => {
  const navigate = useNavigate();
  const { isSupervisor } = useUserRole();
  const { t } = useTranslation();
  const { data: emailAccounts } = trpc.mailbox.accounts.list.useQuery();

  const canSaveFiltersLocally = useSelector(
    (state: RootState) => state?.user?.settings?.saveFilters,
  );

  const [dashboardSettings, setDashboardSettings] = useState<DashboardSettings>(() => {
    const savedSettings = localStorage.getItem(localStorageKeys.dashboard.FILTERS);
    return savedSettings ? JSON.parse(savedSettings) : defaultSettings;
  });

  const oldAccounts = useMemo(() => {
    if (!emailAccounts) return [];

    return emailAccounts.filter((ea) => !ea.isNewAccount);
  }, [emailAccounts]);

  const handleNavigateToRelink = () => {
    if (oldAccounts.some((ea) => ea.isTeamMailbox) && isSupervisor) {
      navigate('/team/settings/communication');
      return;
    }
    if (oldAccounts.every((ea) => !ea.isTeamMailbox)) navigate('/user/settings/email-accounts');
  };

  useEffect(() => {
    if (canSaveFiltersLocally === undefined) return;
    if (!canSaveFiltersLocally) {
      setDashboardSettings(defaultSettings);
      return;
    }
    const storageString = localStorage.getItem(localStorageKeys.dashboard.FILTERS);
    if (!storageString) {
      setDashboardSettings(defaultSettings);
      return;
    }

    setDashboardSettings(JSON.parse(storageString));
  }, [canSaveFiltersLocally]);

  useEffect(() => {
    if (canSaveFiltersLocally !== true) return;
    localStorage.setItem(localStorageKeys.dashboard.FILTERS, JSON.stringify(dashboardSettings));
  }, [canSaveFiltersLocally, dashboardSettings]);

  useEffect(() => {
    if (canSaveFiltersLocally !== false) return;
    localStorage.removeItem(localStorageKeys.dashboard.FILTERS);
  }, [canSaveFiltersLocally]);

  return (
    <KpcFullPage>
      <DashboardTitle
        setDashboardSettings={setDashboardSettings}
        dashboardSettings={dashboardSettings}
      />
      {!!oldAccounts.length && (
        <Alert
          className="w-50 my-2"
          size="lg"
          startDecorator={<WarningIcon />}
          color="danger"
          endDecorator={
            isSupervisor || oldAccounts.some((ea) => !ea.isTeamMailbox) ? (
              <IconButton variant="plain" color="neutral" onClick={handleNavigateToRelink}>
                <OpenIcon />
              </IconButton>
            ) : undefined
          }
        >
          {t('followingAccountsNeedToBeRelinked', {
            accounts: oldAccounts.map((x) => x.emailAddress).join(', '),
          })}
        </Alert>
      )}
      {dashboardSettings.showStatistics && <DashboardHeader />}
      <div className={classNames(`grid grid-cols-${dashboardSettings.columnCount} gap-4`)}>
        {dashboardSettings.showTasksChart && <OpenTasksByTaskTypeChart />}
        {dashboardSettings.showTasksTable && <OpenTaskTable />}
      </div>
    </KpcFullPage>
  );
};

export default Dashboard;
