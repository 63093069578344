import {
  Annotation,
  BookmarkView,
  FormDesigner,
  FormFields,
  Inject,
  LinkAnnotation,
  Magnification,
  Navigation,
  PdfViewerComponent,
  Print,
  TextSearch,
  TextSelection,
  ThumbnailView,
  Toolbar,
  ToolbarItem,
} from '@syncfusion/ej2-react-pdfviewer';
import { CSSProperties, forwardRef, useContext } from 'react';

import { Nullable } from '~/common/types';

import { AppContext } from '@/shared/context/context';
import { getAccessToken } from '@/shared/utils/authUtils';

const { VITE_KEYPOINT_API_URI } = import.meta.env;

type Props = {
  document: {
    id?: Nullable<string>;
    fileUri?: Nullable<string>;
  };
  allowImport?: boolean;
  viewOnly?: boolean;
  className?: string;
  style?: CSSProperties;
};

const TOOLBAR_ITEMS: ToolbarItem[] = [
  'PageNavigationTool',
  'MagnificationTool',
  'SelectionTool',
  'PanTool',
  'SearchOption',
  'PrintOption',
  'DownloadOption',
];

const getToolbarItems = (viewOnly: boolean, allowImport: boolean) => {
  const toolbar = TOOLBAR_ITEMS;

  if (!viewOnly) {
    toolbar.concat(['UndoRedoTool', 'AnnotationEditTool', 'FormDesignerEditTool', 'SubmitForm']);
  }
  if (allowImport) {
    toolbar.push('OpenOption');
  }

  return toolbar;
};

const PdfViewer = forwardRef<PdfViewerComponent, Props>(
  ({ document: doc, className, style, viewOnly = false, allowImport = true }, ref) => {
    const { currentTeam } = useContext(AppContext);

    const { fileUri, id } = doc;

    if (!fileUri && !id) {
      return <span>Missing document uri</span>;
    }

    const accessToken = getAccessToken();

    return (
      <PdfViewerComponent
        ref={ref}
        className={className}
        documentPath={id || fileUri}
        serviceUrl={`${VITE_KEYPOINT_API_URI}/pdfviewer`}
        style={style}
        ajaxRequestSettings={{
          ajaxHeaders: [
            {
              headerName: 'Authorization',
              headerValue: `Bearer ${accessToken}`,
            },
            {
              headerName: 'Team',
              headerValue: currentTeam?.id ?? '',
            },
          ],
        }}
        toolbarSettings={{
          toolbarItems: getToolbarItems(viewOnly, allowImport),
        }}
      >
        <Inject
          services={[
            Toolbar,
            Magnification,
            Navigation,
            ThumbnailView,
            Print,
            TextSelection,
            TextSearch,
            ...(!viewOnly
              ? [Annotation, LinkAnnotation, BookmarkView, FormFields, FormDesigner]
              : []),
          ]}
        />
      </PdfViewerComponent>
    );
  },
);

export default PdfViewer;
