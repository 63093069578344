import { Checkbox, Dropdown, IconButton, Menu, MenuButton, MenuItem, Typography } from '@mui/joy';
import { useAuth } from 'react-oidc-context';
import { useTranslation } from 'react-i18next';

import { AccessibilityIcon, ColumnGridIcon } from '@/shared/icons/Icons';
import DashboardSettings from '../dashboard.types';

type Props = {
  setDashboardSettings: React.Dispatch<React.SetStateAction<DashboardSettings>>;
  dashboardSettings: DashboardSettings;
};

const DashboardTitle: React.FC<Props> = ({ setDashboardSettings, dashboardSettings }) => {
  const auth = useAuth();
  const profile = auth.user?.profile;
  const { t } = useTranslation();

  return (
    <div className="flex items-center justify-between">
      <Typography
        level="h1"
        className="mb-4 text-2xl font-medium"
        startDecorator={
          <Typography level="h1" className="text-2xl">
            👋
          </Typography>
        }
      >
        {t('dashboard.title', { name: profile?.name })}
      </Typography>

      <div className="space-x-4">
        <Dropdown>
          <MenuButton slots={{ root: IconButton }}>
            <ColumnGridIcon />
          </MenuButton>
          <Menu placement="bottom-end">
            <MenuItem>
              <Checkbox
                checked={dashboardSettings.columnCount === 1}
                onChange={() => setDashboardSettings((prev) => ({ ...prev, columnCount: 1 }))}
                label="1x"
              />
            </MenuItem>
            <MenuItem>
              <Checkbox
                checked={dashboardSettings.columnCount === 2}
                onChange={() => setDashboardSettings((prev) => ({ ...prev, columnCount: 2 }))}
                label="2x"
              />
            </MenuItem>
          </Menu>
        </Dropdown>

        <Dropdown>
          <MenuButton slots={{ root: IconButton }}>
            <AccessibilityIcon />
          </MenuButton>
          <Menu placement="bottom-end">
            <MenuItem>
              <Checkbox
                onChange={(e) =>
                  setDashboardSettings((prev) => ({
                    ...prev,
                    showStatistics: e.target.checked,
                  }))
                }
                checked={dashboardSettings.showStatistics}
                label={t('dashboard.widgets.statsHeader')}
              />
            </MenuItem>
            <MenuItem>
              <Checkbox
                onChange={(e) =>
                  setDashboardSettings((prev) => ({
                    ...prev,
                    showTasksChart: e.target.checked,
                  }))
                }
                checked={dashboardSettings.showTasksChart}
                label={t('dashboard.widgets.openTasksByTypeChart')}
              />
            </MenuItem>
            <MenuItem>
              <Checkbox
                onChange={(e) =>
                  setDashboardSettings((prev) => ({
                    ...prev,
                    showTasksTable: e.target.checked,
                  }))
                }
                checked={dashboardSettings.showTasksTable}
                label={t('dashboard.widgets.openTasksTable')}
              />
            </MenuItem>
          </Menu>
        </Dropdown>
      </div>
    </div>
  );
};

export default DashboardTitle;
