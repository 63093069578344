import {
  Attributes,
  createElement,
  Dispatch,
  SetStateAction,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { Box, Tooltip } from '@mui/joy';
import { SortingMode } from 'ka-table';
import { upperFirst } from 'lodash';
import { useTranslation } from 'react-i18next';
import useTruncated from '@/shared/hooks/useTruncated';

import { ArrowDownIcon, ArrowUpIcon } from '@/shared/icons/Icons';
import { ColumnProps, HeadCellProps, SortSettings } from '@/shared/hooks/table/types';
import { Obj } from '~/common/types';

type Props = Obj &
  HeadCellProps & {
    type?: string | null;
    column: ColumnProps;
    sortSettings: SortSettings[];
    setSortSettings: Dispatch<SetStateAction<SortSettings[]>>;
  } & Attributes;

const SortableHeadCell: React.FC<Props> = (props) => {
  const { type = null, column, sortSettings, setSortSettings, sortingMode } = props;

  const { childRef, containerRef, isTruncated } = useTruncated();
  const [sortSetting, setSortSetting] = useState<SortSettings | null>();
  const { t } = useTranslation();

  useEffect(() => {
    setSortSetting(
      sortSettings.find((setting) => setting.sortColumn === upperFirst(column.key)) ?? null,
    );
  }, [sortSettings, column.key]);

  const label = useMemo(() => {
    const { title, key } = column;

    if (!type && !title) return '';

    return title ? t(title) : t(`_${type}.columns.${key}`);
  }, [column, t, type]);

  if (column.customHeaderComponent) {
    return createElement(column.customHeaderComponent, props);
  }

  const handleClick = () => {
    const columnName = upperFirst(column.key);

    if (sortSetting) {
      const filteredSortSettings =
        sortSettings.filter(
          (settings) => settings.sortColumn !== columnName && settings.sortColumn !== '',
        ) ?? [];

      // deselect filter
      if (!sortSetting.defaultSortOrder) {
        setSortSettings(filteredSortSettings);
        return;
      }

      if (sortingMode === SortingMode.Single) {
        setSortSettings([
          {
            defaultSortOrder: false,
            sortColumn: columnName,
          },
        ]);
        return;
      }

      setSortSettings([
        ...filteredSortSettings,
        {
          defaultSortOrder: false,
          sortColumn: columnName,
        },
      ]);
      return;
    }

    if (sortingMode === SortingMode.Single) {
      setSortSettings([
        {
          defaultSortOrder: true,
          sortColumn: columnName,
        },
      ]);
      return;
    }

    setSortSettings((prevState) => [
      ...prevState,
      {
        defaultSortOrder: true,
        sortColumn: columnName,
      },
    ]);
  };
  return (
    <Box ref={containerRef} onClick={handleClick}>
      <div className="inline-block" ref={childRef}>
        {!isTruncated ? (
          <div className="inline-block whitespace-nowrap">
            {label}
            {sortSetting?.sortColumn === upperFirst(column.key) &&
              (sortSetting?.defaultSortOrder ? (
                <ArrowDownIcon className="pl-2" size="sm" />
              ) : (
                <ArrowUpIcon className="pl-2" size="sm" />
              ))}
          </div>
        ) : (
          <Tooltip className="whitespace-nowrap" placement="top" title={label}>
            <div className="inline-block">{label}</div>
          </Tooltip>
        )}
      </div>
    </Box>
  );
};

export default { SortableHeadCell };
