import { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';

import { Autocomplete, FormControl, FormLabel, Grid, Input } from '@mui/joy';

import { useAutocompleteEnum, useAutocompleteOptions } from '@/shared/hooks/autocomplete';
import QueryInvoicingInvoiceRequest from '~/common/types/finance/invoicingInvoices/invoicingInvoice';
import { trpc } from '@/config/trpc';

import DateRangePicker from '@/shared/components/2.0/DateRangePicker';
import { InvoiceCreationStatus } from '~/common/enums';

type Props = {
  filters: QueryInvoicingInvoiceRequest;
  setFilters: Dispatch<SetStateAction<QueryInvoicingInvoiceRequest>>;
};

const SalesInvoicesFilters: React.FC<Props> = ({ filters, setFilters }) => {
  const { t } = useTranslation();
  const { data: homeownerAssociations } = trpc.hoa.autocomplete.useQuery({
    showOnlyRegisteredHomeownerAssociations: false,
  });

  const homeownerAssociationAutocomplete = useAutocompleteOptions({
    callback: (option) => setFilters({ ...filters, homeownerAssociationId: option?.value || null }),
    data: homeownerAssociations,
    selected: filters.homeownerAssociationId,
  });

  const invoiceCreationStatusAutocomplete = useAutocompleteEnum({
    callback: (value) => setFilters({ ...filters, invoiceCreationStatus: value?.value || null }),
    selected: filters.invoiceCreationStatus,
    srcEnum: InvoiceCreationStatus,
    translationKey: 'invoiceCreationStatus',
  });

  return (
    <Grid spacing={1} marginBottom={2} container>
      <Grid xs={3}>
        <FormControl>
          <FormLabel>{t('selectType', { type: t('homeownerAssociation') })}</FormLabel>
          <Autocomplete
            {...homeownerAssociationAutocomplete}
            placeholder={t('homeownerAssociation')}
          />
        </FormControl>
      </Grid>
      <Grid xs={3}>
        <DateRangePicker
          label={t('date')}
          value={{ endDate: filters.untilDate, startDate: filters.fromDate }}
          onChange={(value) => {
            if (value?.startDate && value.endDate) {
              setFilters({
                ...filters,
                fromDate: value.startDate,
                untilDate: value.endDate,
              });
              return;
            }

            setFilters({ ...filters, fromDate: null, untilDate: null });
          }}
        />
      </Grid>
      <Grid xs={3}>
        <FormControl>
          <FormLabel>{t('amount')}</FormLabel>
          <Input
            type="number"
            onChange={(e) => {
              if (!e.target.value) {
                setFilters({ ...filters, amount: null });
                return;
              }

              const parsedNumber = parseFloat(e.target.value);
              setFilters({ ...filters, amount: parsedNumber });
            }}
          />
        </FormControl>
      </Grid>
      <Grid xs={3}>
        <FormControl>
          <FormLabel>{t('status')}</FormLabel>
          <Autocomplete {...invoiceCreationStatusAutocomplete} placeholder={t('status')} />
        </FormControl>
      </Grid>
    </Grid>
  );
};

export default SalesInvoicesFilters;
