import Datepicker, { DateValueType } from 'react-tailwindcss-datepicker';
import { FormControl, FormLabel } from '@mui/joy';
import classNames from 'classnames';
import { t } from 'i18next';
import { useTranslation } from 'react-i18next';

import { Nullable } from '~/common/types';
import useDayjs from '@/shared/hooks/useDayjs';

const START_WEEK_ON = 'mon';

const datePickerStyles =
  'pr-14 text-sm w-full border-gray-300 h-8 border rounded-md pl-[0.6rem] hover:border-[#010102]';

type Props = {
  className?: string;
  datePickerClassName?: string;
  label?: string;
  loading?: boolean;
  maxDate?: Nullable<Date>;
  minDate?: Nullable<Date>;
  onChange: (value: DateValueType) => void;
  placeholder?: string;
  required?: boolean;
  value: DateValueType;
};

const DateRangePicker: React.FC<Props> = ({
  className,
  datePickerClassName,
  label = null,
  loading = false,
  maxDate,
  minDate,
  onChange,
  placeholder = null,
  required,
  value,
}) => {
  const {
    i18n: { language },
  } = useTranslation();
  const { dayjs, localeData } = useDayjs();
  const formatString = localeData.longDateFormat('L');
  const today = dayjs().toDate();

  const handleChange = (newValue: DateValueType) => {
    onChange(newValue);
  };

  return (
    <FormControl className={classNames(className, 'flex grow')}>
      {label && <FormLabel required={required}>{label}</FormLabel>}
      <Datepicker
        i18n={language}
        placeholder={placeholder ?? t('_dateRangePicker.placeholder')}
        disabled={loading}
        inputClassName={classNames(datePickerStyles, datePickerClassName)}
        value={value}
        startWeekOn={START_WEEK_ON}
        onChange={handleChange}
        displayFormat={formatString}
        minDate={minDate}
        maxDate={maxDate}
        startFrom={maxDate && maxDate < today ? maxDate : today}
      />
    </FormControl>
  );
};

export default DateRangePicker;
