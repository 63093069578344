import { useTranslation } from 'react-i18next';

import { Dropdown, Menu, MenuButton, MenuItem } from '@mui/joy';

import downloadFile from '@/shared/utils/fileHelpers';
import { InvoiceCreationStatus } from '~/common/enums';
import { trpc } from '@/config/trpc';

import { EllipsisIcon, InlineSpinner } from '@/shared/icons/Icons';

type Props = {
  rowData: {
    invoiceId: string;
    invoiceNumber: string;
    invoiceCreationStatus: InvoiceCreationStatus;
  };
};

const InvoiceActionsCustomCell: React.FC<Props> = ({
  rowData: { invoiceId, invoiceNumber, invoiceCreationStatus },
}) => {
  const { t } = useTranslation();
  const { mutateAsync: getPreviewUriAsync, isLoading } =
    trpc.finance.invoicingInvoices.getPreviewUri.useMutation();

  const downloadAsync = async () => {
    const data = await getPreviewUriAsync(invoiceId);

    downloadFile({ fileName: `${invoiceNumber}.pdf`, fileUri: data });
  };

  return (
    <Dropdown>
      <MenuButton variant="plain" color="neutral">
        {!isLoading ? <EllipsisIcon /> : <InlineSpinner />}
      </MenuButton>
      <Menu placement="bottom-start">
        <MenuItem
          disabled={invoiceCreationStatus !== InvoiceCreationStatus.Done}
          onClick={downloadAsync}
        >
          {t('download')}
        </MenuItem>
      </Menu>
    </Dropdown>
  );
};

export default InvoiceActionsCustomCell;
