import { lowerFirst } from 'lodash';
import { SortingMode } from 'ka-table';

import { customCellWidth, localStorageKeys } from '@/shared/utils/constants';
import { InvoicingInvoiceSortingColumns } from '~/common/enums';

import CurrencyCell from '@/shared/components/2.0/customTableCells/CurrencyCell';
import DateCell from '@/shared/components/2.0/customTableCells/DateCell';
import InvoiceActionsCustomCell from './CustomCells/InvoiceActionsCustomCell';
import InvoiceCreationModeCustomCell from './CustomCells/InvoiceCreationModeCustomCell';
import InvoiceCreationStatusCustomCell from './CustomCells/InvoiceCreationStatusCustomCell';
import InvoicePreviewCustomCell from './CustomCells/InvoicePreviewCustomCell';

enum ColumnKey {
  HomeownerAssociationName = 'homeownerAssociationName',
  InvoiceDate = 'invoiceDate',
  InvoiceNumber = 'invoiceNumber',
  VatInclusiveTotalAmount = 'vatInclusiveTotalAmount',
  InvoiceCreationMode = 'invoiceCreationMode',
  InvoiceCreationStatus = 'invoiceCreationStatus',
}

const getColumnKey = (column: ColumnKey) => `_syndicManagement.salesInvoices.columns.${column}`;

export const sortableColumns = [
  lowerFirst(InvoicingInvoiceSortingColumns.HomeownerAssociationName),
  lowerFirst(InvoicingInvoiceSortingColumns.InvoiceDate),
  lowerFirst(InvoicingInvoiceSortingColumns.InvoiceNumber),
  lowerFirst(InvoicingInvoiceSortingColumns.VatInclusiveTotalAmount),
  lowerFirst(InvoicingInvoiceSortingColumns.InvoiceCreationMode),
];

export const tableConfig = {
  columns: [
    {
      key: lowerFirst(InvoicingInvoiceSortingColumns.HomeownerAssociationName),
      title: getColumnKey(ColumnKey.HomeownerAssociationName),
    },
    {
      customCellComponent: DateCell,
      key: lowerFirst(InvoicingInvoiceSortingColumns.InvoiceDate),
      title: getColumnKey(ColumnKey.InvoiceDate),
    },
    {
      key: lowerFirst(InvoicingInvoiceSortingColumns.InvoiceNumber),
      title: getColumnKey(ColumnKey.InvoiceNumber),
    },
    {
      customCellComponent: CurrencyCell,
      key: lowerFirst(InvoicingInvoiceSortingColumns.VatInclusiveTotalAmount),
      title: getColumnKey(ColumnKey.VatInclusiveTotalAmount),
    },
    {
      customCellComponent: InvoiceCreationModeCustomCell,
      key: lowerFirst(InvoicingInvoiceSortingColumns.InvoiceCreationMode),
      title: getColumnKey(ColumnKey.InvoiceCreationMode),
    },
    {
      customCellComponent: InvoiceCreationStatusCustomCell,
      key: ColumnKey.InvoiceCreationStatus,
      title: getColumnKey(ColumnKey.InvoiceCreationStatus),
    },
    {
      customCellComponent: InvoicePreviewCustomCell,
      key: 'invoiceId',
      title: 'preview',
      width: customCellWidth.S,
    },
    {
      allowDeselect: false,
      customCellComponent: InvoiceActionsCustomCell,
      key: 'id',
      title: '',
      width: customCellWidth.S,
    },
  ],
  localStorageKey: localStorageKeys.syndicManagement.salesInvoices.COLUMNS,
  rowId: 'id',
  rowKeyField: 'id',
  sortingMode: SortingMode.Single,
};
