import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import Routes from '@/containers/App/Router/Routes';
import { trpc } from '@/config/trpc';

import { NewTabIcon } from '@/shared/icons/Icons';
import Row from '@/shared/components/2.0/layout/Row';

type SquareLinkButtonProps = {
  className?: string;
  label: string;
  value?: number;
};

const SquareLinkButton: React.FC<SquareLinkButtonProps> = ({ className, label, value }) => (
  <Link
    to={Routes.CASE_OVERVIEW}
    className={classNames(
      className,
      'flex h-36 w-36 flex-col space-y-2 rounded-lg border bg-blue-200 p-2 hover:bg-blue-300',
    )}
  >
    <NewTabIcon className="self-end text-gray-800" />
    <p className="text-center">{label}</p>
    <p className="flex grow items-center justify-center text-xl font-bold">{value}</p>
  </Link>
);

const DashboardHeader = () => {
  const { t } = useTranslation();

  const { data } = trpc.home.caseAmount.useQuery();

  return (
    <Row>
      <SquareLinkButton
        label={t('dashboard.highlights.pendingCases')}
        value={data?.pendingCaseAmount?.totalCases}
      />
      <SquareLinkButton
        label={t('dashboard.highlights.openCasesWithNoTasks')}
        value={data?.totalCasesWithoutOpenTasks}
      />
      <SquareLinkButton
        label={t('dashboard.highlights.openCases')}
        value={data?.openCaseAmount?.totalCases}
      />
      <SquareLinkButton
        label={t('dashboard.highlights.openCaseGroups')}
        value={data?.openCaseAmount?.totalCaseGroups}
      />
    </Row>
  );
};

export default DashboardHeader;
