import { createElement, useState } from 'react';
import { FormControl, FormHelperText, FormLabel, Autocomplete as MuiAutocomplete } from '@mui/joy';
import PropTypes from 'prop-types';
import { v4 as uuidv4 } from 'uuid';

const autoCompletePropTypes = PropTypes.shape({
  label: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
});

const propTypes = {
  disabled: PropTypes.bool,
  error: PropTypes.bool,
  freeSolo: PropTypes.bool,
  helperText: PropTypes.string,
  icon: PropTypes.func,
  isRequired: PropTypes.bool,
  label: PropTypes.string,
  loading: PropTypes.bool,
  multiple: PropTypes.bool,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    }),
  ).isRequired,
  placeholder: PropTypes.string,
  value: PropTypes.oneOfType([autoCompletePropTypes, PropTypes.arrayOf(autoCompletePropTypes)]),
};

const Autocomplete = ({
  disabled = false,
  error = false,
  freeSolo = false,
  helperText = null,
  icon = undefined,
  isRequired = false,
  label,
  loading = false,
  multiple = false,
  name,
  onChange,
  options,
  placeholder = '',
  value,
  ...rest
}) => {
  const [uncontrolledValue, setUncontrolledValue] = useState(multiple ? [] : null);

  const handleValueChange = (changedValue) => {
    onChange({ name, type: 'autocomplete', value: changedValue });
    if (value === undefined) {
      setUncontrolledValue(changedValue);
    }
  };

  return (
    <FormControl required={isRequired} className="w-full min-w-0 flex-1" size="sm" error={error}>
      {label && (
        <FormLabel required={isRequired} className={error ? 'invalid' : ''}>
          {label}
        </FormLabel>
      )}
      <MuiAutocomplete
        freeSolo={freeSolo}
        clearOnBlur={!freeSolo}
        disabled={disabled || loading}
        disableCloseOnSelect={multiple}
        multiple={multiple}
        onChange={(e, val) => handleValueChange(val)}
        onInputChange={(e, val) => (freeSolo ? handleValueChange(val) : {})}
        options={options}
        placeholder={placeholder}
        startDecorator={icon ? createElement(icon) : undefined}
        isOptionEqualToValue={(option, val) => option.value === val.value}
        getOptionKey={() => uuidv4()}
        value={value || uncontrolledValue}
        {...rest}
      />
      {helperText && (
        <FormHelperText className={error ? 'validation-message' : ''}>{helperText}</FormHelperText>
      )}
    </FormControl>
  );
};

Autocomplete.propTypes = propTypes;

export default Autocomplete;
