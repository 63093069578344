import { lowerFirst } from 'lodash';
import { TFunction } from 'i18next';

import { InvoiceCreationStatus } from '~/common/enums';

type Props = {
  rowData: { invoiceCreationStatus: InvoiceCreationStatus };
  t: TFunction;
};

const InvoiceCreationStatusCustomCell: React.FC<Props> = ({
  rowData: { invoiceCreationStatus },
  t,
}) => t(`invoiceCreationStatus.${lowerFirst(invoiceCreationStatus)}`);

export default InvoiceCreationStatusCustomCell;
