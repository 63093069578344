import classNames from 'classnames';
import PropTypes from 'prop-types';

import PageTitle from '../../../containers/Layout/PageTitle';

const KpcPage = ({
  title,
  subTitle,
  breadCrumb,
  currentPage,
  headerComponent,
  children,
  className,
}) => (
  <div className={classNames('flex flex-col space-y-4 p-4', className)}>
    {!!title?.length && (
      <PageTitle
        breadCrumb={breadCrumb}
        currentPage={currentPage}
        subTitle={subTitle}
        title={title}
      />
    )}

    {headerComponent && <div>{headerComponent}</div>}
    {children}
  </div>
);

export default KpcPage;

KpcPage.propTypes = {
  breadCrumb: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      route: PropTypes.string.isRequired,
    }),
  ),
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  className: PropTypes.string,
  currentPage: PropTypes.string,
  headerComponent: PropTypes.node,
  subTitle: PropTypes.string,
  title: PropTypes.string,
};

KpcPage.defaultProps = {
  breadCrumb: [],
  children: [],
  className: '',
  currentPage: '',
  headerComponent: null,
  subTitle: '',
  title: '',
};
