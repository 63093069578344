import { ITableAllProps, ITableProps, SortingMode, Table } from 'ka-table';
import React, {
  Dispatch,
  SetStateAction,
  SyntheticEvent,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { AttributeTableData } from 'ka-table/models';
import { TRPCClientErrorLike } from '@trpc/client';
import { useTranslation } from 'react-i18next';
import { UseTRPCInfiniteQueryResult } from '@trpc/react-query/shared';

import { getStoredColumns, saveColumns } from '@/shared/utils/helpers';
import { AppContext } from '@/shared/context/context';
import { UseToggle } from '@/shared/hooks/UseToggle';
import useToggleHeight from '@/shared/hooks/useToggleHeight';

import DataTableFilter from '@/shared/components/2.0/DataTableFilter';
import DataTableSearch from '@/shared/components/2.0/DataTableSearch';
import DefaultHeaderCell from '@/shared/components/2.0/customTableCells/DefaultHeaderCell';
import DefaultTableCell from '@/shared/components/2.0/customTableCells/DefaultTableCell';
import MultiSelectColumns from '@/shared/components/2.0/MultiSelectColumns';
import signalRMessages from '@/redux/actions/signalRMessages';
import SortableHeadCell from '@/shared/components/2.0/customTableCells/SortableHeaderCell';

import { SortSettings } from '@/shared/hooks/table/types';

type ScrollableTableProps<T> = {
  fetchResult: UseTRPCInfiniteQueryResult<
    {
      items: T[];
      nextPage: number | null;
      totalCount?: number;
    },
    TRPCClientErrorLike<any>
  >;
  config: {
    columns: ITableAllProps['columns'];
    localStorageKey: string;
    rowId: string;
    groups?: ITableAllProps['groups'];
    rowKeyField: ITableAllProps['rowKeyField'];
    childComponents?: ITableAllProps['childComponents'];
    sortingMode: ITableAllProps['sortingMode'];
  };
  title?: string;
  toggle: UseToggle;
  filter: React.ReactNode;
  setSelected?: (row: T) => void;
  sortableColumns?: string[];
  setSortSettings?: Dispatch<SetStateAction<SortSettings[]>>;
  sortSettings?: SortSettings[];
  setSearchText?: (searchText: string) => void;
  offsetY?: number;
};

const PaginatedTable = <T,>({
  fetchResult,
  config,
  setSelected,
  sortableColumns,
  setSortSettings,
  sortSettings,
  title,
  toggle,
  filter,
  setSearchText,
  offsetY,
}: ScrollableTableProps<T>) => {
  const {
    data: pagedData,
    isFetchingNextPage,
    hasNextPage,
    fetchNextPage,
    isLoading,
    isFetching,
    refetch,
  } = fetchResult;

  const [tableSearchText, setTableSearchText] = useState('');
  useEffect(() => {
    setSearchText?.(tableSearchText);
  }, [setSearchText, tableSearchText]);

  const data = useMemo(() => {
    if (!pagedData?.pages) {
      return [];
    }
    return pagedData.pages
      .flatMap((page) => page.items)
      .map((item, index) => ((item as { id: string }).id ? item : { ...item, id: index }));
  }, [pagedData]);

  const { socket } = useContext(AppContext);

  useEffect(() => {
    socket?.on(signalRMessages.ReconciliationRequestCompleted, () => refetch());
    return () => {
      socket?.off(signalRMessages.ReconciliationRequestCompleted);
    };
  }, [refetch, socket]);

  const { t } = useTranslation();
  const { columns, localStorageKey, rowId, groups, rowKeyField, childComponents, sortingMode } =
    config;

  const [tableProps, setTableProps] = useState({
    columns: getStoredColumns(columns ?? [], localStorageKey),
    groups,
    localStorageKey,
    rowId,
    rowKeyField,
  });

  const dispatch = useCallback(
    (action: any) => {
      setTableProps((prevState) =>
        saveColumns({ action, localStorageType: localStorageKey, prevState }),
      );
    },
    [localStorageKey],
  );

  const handleScrollMore = (e: SyntheticEvent, options: AttributeTableData<ITableProps>) => {
    const { baseFunc } = options;
    baseFunc(e);
    const tableWrapper = e.currentTarget as HTMLHtmlElement;
    const scrollMore =
      tableWrapper.scrollTop + 1 > tableWrapper.scrollHeight - tableWrapper.offsetHeight;
    if (!isLoading && !isFetchingNextPage && hasNextPage && scrollMore) {
      fetchNextPage();
    }
  };

  const filterToggle = useToggleHeight({
    localStorageKey,
    offsetY: offsetY ?? 300,
  });

  return (
    <div className="rounded-xl bg-white p-4 pt-0">
      <div className="flex items-end space-x-2 pb-4">
        <div className="grow">{title && <h4>{title}</h4>}</div>
        <div className="flex space-x-2">
          {setSearchText && <DataTableSearch setSearchText={setTableSearchText} />}
          <DataTableFilter toggle={filterToggle} />
          <MultiSelectColumns columns={tableProps.columns} onSelectFilter={dispatch} />
        </div>
      </div>
      <div ref={filterToggle.divRef}>{filterToggle.value && filter}</div>
      {(isFetching || !!pagedData) && (
        <div className="rounded-2xl border border-gray-200">
          <Table
            {...tableProps}
            height={filterToggle.height}
            loading={{ enabled: isLoading || isFetchingNextPage }}
            data={data}
            dispatch={dispatch}
            sortingMode={sortingMode ?? SortingMode.None}
            childComponents={{
              ...childComponents,
              cell: {
                content: (props) =>
                  DefaultTableCell.DefaultTableCell({
                    rowId,
                    setSelected,
                    t,
                    toggle,
                    ...props,
                  }),
              },
              headCell: {
                content: (props) => {
                  if (
                    sortableColumns &&
                    setSortSettings &&
                    sortableColumns.includes(props.column.key)
                  ) {
                    return SortableHeadCell.SortableHeadCell({
                      setSortSettings,
                      sortSettings: sortSettings ?? [],
                      t,
                      ...props,
                    });
                  }

                  return DefaultHeaderCell.DefaultHeadCell({
                    t,
                    ...props,
                  });
                },
              },
              noDataRow: !isLoading ? { content: () => t('noDataToDisplay') } : undefined,
              tableWrapper: {
                elementAttributes: () => ({
                  onScroll: handleScrollMore,
                }),
              },
            }}
          />
        </div>
      )}
    </div>
  );
};

export default PaginatedTable;
