import * as yup from 'yup';
import {
  Alert,
  Autocomplete,
  AutocompleteOption,
  Button,
  Checkbox,
  Chip,
  FormControl,
  FormLabel,
  IconButton,
  Input,
  ListItemContent,
  Stack,
  Textarea,
  Tooltip,
  Typography,
} from '@mui/joy';
import { useContext, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { lowerFirst } from 'lodash';
import { useFormik } from 'formik';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';

import CaseRelationRole from '@/shared/enums/CaseRelationRole';
import SpreadsheetType from '~/frontend/shared/enums/SpreadsheetType';
import { UseToggle } from '@/shared/hooks/UseToggle';

import { RouterOutputs, trpc } from '@/config/trpc';
import { actions } from '@/reducers/ui';
import { AppContext } from '@/shared/context/context';

import {
  CancelIcon,
  FileIcon,
  HomeOwnerAssociationIcon,
  InlineSpinner,
  InsuranceEngineerIcon,
  InsuranceIntermediaryIcon,
  InsurerIcon,
  PropertyManagerIcon,
  SaveIcon,
  SpreadsheetIcon,
  UndoIcon,
  UploadIcon,
  WarningIcon,
} from '@/shared/icons/Icons';
import KpcModal from '@/shared/components/2.0/layout/KpcModal';
import UploadFileModal from './UploadFileModal';

type CaseFiles = RouterOutputs['case']['files'];

type Props = {
  title: string;
  type?: typeof SpreadsheetType;
  toggle: UseToggle<unknown>;
};

const UploadOfferModal: React.FC<Props> = ({ title, type, toggle }) => {
  const { t } = useTranslation();
  const { caseId } = useParams() as { caseId: string };
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const [navigateTo, setNavigateTo] = useState<boolean>(false);

  const { ui, setUi } = useContext(AppContext);
  const [selectedImages, setSelectedImages] = useState<CaseFiles>([]);

  const { data: currentCase } = trpc.case.byId.useQuery(caseId);
  const { data: currentCaseFiles } = trpc.case.files.useQuery(caseId);

  const {
    mutateAsync: createSpreadsheetAsync,
    isLoading: createSpreadsheetAsyncIsProcessing,
    error,
  } = trpc.finance.spreadsheet.createSpreadsheet.useMutation();

  const images = currentCaseFiles?.filter((file) => file.contentType?.includes('image')) ?? [];
  const files = currentCaseFiles?.filter((file) => file.contentType?.includes('pdf')) ?? [];

  const sender = currentCase?.relations.find((relation) => relation.isContractor) || null;
  const receiver = currentCase?.relations.find((relation) => relation.isOwner) || null;

  const relationsWithAutocompleteOptions = currentCase?.relations.map((relation) => ({
    ...relation,
    label: relation.displayName ?? '',
    value: relation.id,
  }));

  const handleCloseModal = () => {
    toggle.hide();
    setUi({
      payload: null,
      type: actions.FILES_SET_MAIN_FILE,
    });
    setUi({
      payload: [],
      type: actions.FILES_SET_TEMPORARY_FILES,
    });
  };

  const formik = useFormik({
    initialValues: {
      caseId,
      description: null,
      images: {
        caseFiles: [],
        uploadedFiles: [],
      },
      mainFile: {
        caseFile: null,
        uploadedFile: null,
      },
      receiver: {
        address: {
          addressLine: receiver?.relationAddressAddressLine,
          city: receiver?.relationAddressCity,
          country: receiver?.relationAddressCountry,
          postalCode: receiver?.relationAddressPostalCode,
        },
        companyName: receiver?.companyName,
        emailAddresses: receiver?.emailAddresses,
        firstName: receiver?.firstName,
        iban: receiver?.iban,
        language: receiver?.language,
        lastName: receiver?.lastName,
        mobilePhoneNumber: receiver?.mobilePhoneNumber,
        phoneNumber: receiver?.phoneNumber,
        teamId: receiver?.teamId,
        vat: receiver?.vat,
      },
      remark: null,
      sender: {
        address: {
          addressLine: sender?.relationAddressAddressLine,
          city: sender?.relationAddressCity,
          country: sender?.relationAddressCountry,
          postalCode: sender?.relationAddressPostalCode,
        },
        companyName: sender?.companyName,
        emailAddresses: sender?.emailAddresses,
        firstName: sender?.firstName,
        iban: sender?.iban,
        language: sender?.language,
        lastName: sender?.lastName,
        mobilePhoneNumber: sender?.mobilePhoneNumber,
        phoneNumber: sender?.phoneNumber,
        teamId: sender?.teamId,
        vat: sender?.vat,
      },
      type: SpreadsheetType.Estimate,
    },
    onSubmit: async (values) => {
      const data = {
        ...values,
        images: {
          caseFiles: selectedImages.map((image) => image.id),
          uploadedFiles: ui.files.temporary,
        },
        mainFile: {
          caseFile: ui.files.main ? null : values.mainFile.caseFile,
          uploadedFile: ui.files.main,
        },
        type: type || SpreadsheetType.Estimate,
      };

      const { data: spreadSheetId } = await createSpreadsheetAsync(data);

      handleCloseModal();

      enqueueSnackbar(t('typeSuccessfullyAdded', { type: t('spreadsheet') }), {
        variant: 'success',
      });

      if (navigateTo) {
        navigate(`/spreadsheet/${spreadSheetId}`);
      }
    },
    validationSchema: yup.object().shape({
      description: yup.string().required().nullable(),
      receiver: yup.object().shape({
        teamId: yup.string().required().nullable(),
      }),
      remark: yup.string().nullable(),
      sender: yup.object().shape({
        teamId: yup.string().required().nullable(),
      }),
    }),
  });

  const { values, setFieldValue, errors, handleSubmit, touched } = formik;

  return (
    <KpcModal
      className="w-2/3"
      icon={<SpreadsheetIcon />}
      title={title}
      toggle={toggle}
      onClose={handleCloseModal}
    >
      <form className="mt-2 flex flex-col space-y-4" onSubmit={handleSubmit}>
        <div className="flex space-x-4">
          <FormControl className="w-1/2" error={!!errors.sender && !!touched.sender}>
            <FormLabel required>{t('sender')}</FormLabel>
            <Autocomplete
              value={
                relationsWithAutocompleteOptions?.find((r) => values.sender.teamId === r.teamId) ||
                null
              }
              className="grow"
              placeholder={t('typeHere')}
              options={relationsWithAutocompleteOptions || []}
              onChange={(_e, relation) => {
                const selectedSender = currentCase?.relations?.find((r) => r.id === relation?.id);
                setFieldValue('sender', {
                  address: {
                    addressLine: selectedSender?.relationAddressAddressLine,
                    city: selectedSender?.relationAddressCity,
                    country: selectedSender?.relationAddressCountry,
                    postalCode: selectedSender?.relationAddressPostalCode,
                  },
                  companyName: selectedSender?.companyName,
                  emailAddresses: selectedSender?.emailAddresses,
                  firstName: selectedSender?.firstName,
                  iban: selectedSender?.iban,
                  language: selectedSender?.language,
                  lastName: selectedSender?.lastName,
                  mobilePhoneNumber: selectedSender?.mobilePhoneNumber,
                  phoneNumber: selectedSender?.phoneNumber,
                  teamId: selectedSender?.teamId,
                  vat: selectedSender?.vat,
                });
              }}
              renderOption={(props, option) => (
                <AutocompleteOption {...props}>
                  <ListItemContent>
                    <Typography
                      startDecorator={
                        <>
                          {option.isOwner && <Chip variant="soft">{t('owner')}</Chip>}
                          {option.isRequestor && <Chip variant="soft">{t('requestor')}</Chip>}
                          {option.isClient && <Chip variant="soft">{t('client')}</Chip>}
                          {option.isContractor && (
                            <Chip variant="soft">{t('_contractor.title')}</Chip>
                          )}
                        </>
                      }
                      endDecorator={
                        <>
                          {option.role === CaseRelationRole.Insurer && <InsurerIcon />}
                          {option.role === CaseRelationRole.InsuranceEngineer && (
                            <InsuranceEngineerIcon />
                          )}
                          {option.role === CaseRelationRole.InsuranceIntermediary && (
                            <InsuranceIntermediaryIcon />
                          )}
                          {option.role === CaseRelationRole.HomeOwnerAssociation && (
                            <HomeOwnerAssociationIcon />
                          )}
                          {option.role === CaseRelationRole.PropertyManager && (
                            <PropertyManagerIcon />
                          )}
                        </>
                      }
                    >
                      {option.displayName}
                    </Typography>
                  </ListItemContent>
                </AutocompleteOption>
              )}
            />
          </FormControl>

          <FormControl className="w-1/2" error={!!errors.receiver && !!touched.receiver}>
            <FormLabel required>{t('receiver')}</FormLabel>
            <Autocomplete
              value={
                relationsWithAutocompleteOptions?.find(
                  (r) => values.receiver.teamId === r.teamId,
                ) || null
              }
              className="grow"
              placeholder={t('typeHere')}
              options={relationsWithAutocompleteOptions || []}
              onChange={(_e, option) => {
                setFieldValue('receiver', {
                  address: {
                    addressLine: option?.relationAddressAddressLine,
                    city: option?.relationAddressCity,
                    country: option?.relationAddressCountry,
                    postalCode: option?.relationAddressPostalCode,
                  },
                  companyName: option?.companyName,
                  emailAddresses: option?.emailAddresses,
                  firstName: option?.firstName,
                  iban: option?.iban,
                  language: option?.language,
                  lastName: option?.lastName,
                  mobilePhoneNumber: option?.mobilePhoneNumber,
                  phoneNumber: option?.phoneNumber,
                  teamId: option?.teamId,
                  vat: option?.vat,
                });
              }}
              renderOption={(props, option) => (
                <AutocompleteOption {...props}>
                  <ListItemContent>
                    <Typography
                      startDecorator={
                        <>
                          {option.isOwner && <Chip variant="soft">{t('owner')}</Chip>}
                          {option.isRequestor && <Chip variant="soft">{t('requestor')}</Chip>}
                          {option.isClient && <Chip variant="soft">{t('client')}</Chip>}
                          {option.isContractor && (
                            <Chip variant="soft">{t('_contractor.title')}</Chip>
                          )}
                        </>
                      }
                      endDecorator={
                        <>
                          {option.role === CaseRelationRole.Insurer && <InsurerIcon />}
                          {option.role === CaseRelationRole.InsuranceEngineer && (
                            <InsuranceEngineerIcon />
                          )}
                          {option.role === CaseRelationRole.InsuranceIntermediary && (
                            <InsuranceIntermediaryIcon />
                          )}
                          {option.role === CaseRelationRole.HomeOwnerAssociation && (
                            <HomeOwnerAssociationIcon />
                          )}
                          {option.role === CaseRelationRole.PropertyManager && (
                            <PropertyManagerIcon />
                          )}
                        </>
                      }
                    >
                      {option.displayName}
                    </Typography>
                  </ListItemContent>
                </AutocompleteOption>
              )}
            />
          </FormControl>
        </div>

        <FormControl error={!!errors.description && !!touched.description}>
          <FormLabel required>{t('description')}</FormLabel>
          <Textarea
            placeholder={t('typeHere')}
            onChange={(e) => setFieldValue('description', e.target.value)}
          />
        </FormControl>
        <FormControl error={!!errors.remark && !!touched.remark}>
          <FormLabel>{t('remark')}</FormLabel>
          <Textarea
            placeholder={t('typeHere')}
            onChange={(e) => setFieldValue('remark', e.target.value)}
          />
        </FormControl>

        {!!error && (
          <Alert startDecorator={<WarningIcon />} color="danger">
            {t('errors:includeFile')}
          </Alert>
        )}

        <FormControl error={!!error}>
          <FormLabel required>{t('file_one')}</FormLabel>
          <Stack direction="row" gap={2}>
            {!ui.files.main && (
              <Autocomplete
                value={files.find((file) => file.id === values.mainFile.caseFile) || null}
                className="grow"
                getOptionKey={(option) => option.id || uuidv4()}
                placeholder={t('typeHere')}
                options={files || []}
                onChange={(_e, mainFile) => {
                  if (mainFile) {
                    if (!ui.files.main) {
                      setFieldValue('mainFile.caseFile', mainFile.id);
                      setFieldValue('mainFile.uploadedFile', null);
                    }
                  }
                }}
                renderOption={(props, option) => (
                  <AutocompleteOption {...props}>
                    <ListItemContent>
                      <Typography startDecorator={<FileIcon />}>{option.fileName}</Typography>
                    </ListItemContent>
                  </AutocompleteOption>
                )}
              />
            )}

            {ui.files.main && (
              <>
                <Input className="grow" startDecorator={<FileIcon />} value={ui.files.main.name} />

                <Tooltip title="Uploaded file selected, reset to list the case files">
                  <IconButton
                    onClick={() => {
                      setUi({
                        payload: null,
                        type: actions.FILES_SET_MAIN_FILE,
                      });

                      setFieldValue('mainFile.caseFile', null);
                    }}
                  >
                    <UndoIcon />
                  </IconButton>
                </Tooltip>
              </>
            )}

            <IconButton
              onClick={() =>
                setUi({
                  payload: {
                    accept: {
                      'application/pdf': [],
                    },
                  },
                  type: actions.CASE_TOGGLE_UPLOAD_FILE,
                })
              }
            >
              <UploadIcon />
            </IconButton>
          </Stack>
        </FormControl>

        <FormControl error={!!error}>
          <FormLabel required>{t('images')}</FormLabel>
          <div className="flex flex-col space-y-4">
            <div className=" flex items-center space-x-4">
              <Autocomplete
                multiple
                className="grow"
                getOptionKey={(option) => option.id || uuidv4()}
                disableCloseOnSelect
                placeholder={t('typeHere')}
                options={images || []}
                value={selectedImages}
                onChange={(_e, value) => setSelectedImages(value)}
                renderOption={(props, option) => (
                  <AutocompleteOption {...props}>
                    <ListItemContent>
                      <Typography
                        className="text-sm"
                        startDecorator={
                          <span className="mr-4 h-12 w-12">
                            <img
                              src={option.fileUri || '#'}
                              alt={option.fileName || 'thumbnail'}
                              className="h-full w-full rounded-lg object-cover"
                            />
                          </span>
                        }
                      >
                        {option.fileName}
                      </Typography>
                    </ListItemContent>
                  </AutocompleteOption>
                )}
              />
              <IconButton
                className="self-center"
                onClick={() => setUi({ type: actions.CASE_TOGGLE_UPLOAD_FILES })}
              >
                <UploadIcon />
              </IconButton>
            </div>

            <div className="flex space-x-4 overflow-x-auto">
              {!!selectedImages.length &&
                selectedImages.map((image) => (
                  <Button
                    variant="plain"
                    color="neutral"
                    key={image.id}
                    className="m-0 h-24 w-24 flex-none rounded-lg border p-0 hover:border-red-500 hover:opacity-60"
                    onClick={() =>
                      setSelectedImages((prev) =>
                        prev.filter((prevImage) => prevImage.id !== image.id),
                      )
                    }
                  >
                    <img
                      src={image.fileUri || '#'}
                      alt={image.fileName || 'thumbnail'}
                      className="h-full w-full rounded-lg object-cover "
                    />
                  </Button>
                ))}

              {!!ui.files.temporary.length &&
                ui.files.temporary.map((metadata) => (
                  <Button
                    variant="plain"
                    color="neutral"
                    key={metadata.name}
                    className="m-0 h-24 w-24 flex-none rounded-lg border p-0 hover:border-red-500 hover:opacity-60"
                    onClick={() =>
                      setUi({
                        payload: ui.files.temporary.filter((file) => file.name !== metadata.name),
                        type: actions.FILES_SET_TEMPORARY_FILES,
                      })
                    }
                  >
                    <img
                      src={metadata.uri || '#'}
                      alt={metadata.blobContainerName || 'thumbnail'}
                      className="h-full w-full rounded-lg object-cover"
                    />
                  </Button>
                ))}
            </div>
          </div>
        </FormControl>

        <div className="flex items-center justify-end space-x-4">
          <Checkbox
            label={t('navigateToType', { type: lowerFirst(t('spreadsheet')) })}
            checked={navigateTo}
            onChange={() => setNavigateTo(!navigateTo)}
          />

          <Button
            variant="outlined"
            color="neutral"
            startDecorator={<CancelIcon />}
            onClick={() => handleCloseModal()}
          >
            {t('cancel')}
          </Button>
          <Button
            disabled={createSpreadsheetAsyncIsProcessing}
            type="submit"
            startDecorator={createSpreadsheetAsyncIsProcessing ? <InlineSpinner /> : <SaveIcon />}
          >
            {t('save')}
          </Button>
        </div>
      </form>

      {ui.case.toggles.uploadFile.modal && <UploadFileModal />}
    </KpcModal>
  );
};

export default UploadOfferModal;
