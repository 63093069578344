import { IconButton, Tooltip, Typography } from '@mui/joy';
import { useContext, useMemo } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { decapitalize, getTaskDescription } from '@/shared/utils/helpers';
import { AppContext } from '@/shared/context/context';
import CasePropTypes from '@/shared/prop-types/CasePropTypes';
import PriorityIcon from '@/shared/icons/PriorityIcon';
import TaskPropTypes from '@/shared/prop-types/TaskPropTypes';
import useDayjs from '@/shared/hooks/useDayjs';
import useToggle from '@/shared/hooks/UseToggle';

import { EditIcon, TeamIcon, UserIcon, WarningIcon } from '@/shared/icons/Icons';
import AddEditTaskModal from '../../../../Tasks/EditTask/AddEditTaskModal';
import FinishTaskButton from '../../../../Tasks/iconButtons/FinishTaskButton';
import KpcAvatarGroup from '@/shared/components/avatars/KpcAvatarGroup';
import SnoozeTaskButton from '../../../../Tasks/iconButtons/SnoozeTaskButton';

const TaskItem = ({ task, canEdit, currentCase }) => {
  const { dayjs, formatDate } = useDayjs();

  const { currentTeamUser } = useContext(AppContext);
  const { t } = useTranslation();
  const editToggle = useToggle();

  const taskCreatedByTooltip = useMemo(() => {
    const owner = task.taskUsers.find((tu) => tu.isOwner)?.displayName;

    const createdBy = owner
      ? t('createdByUser', { user: owner })
      : t('createdByUser', { user: "Vera" });

    return `${createdBy} (${formatDate(task.created)})`;

  }, [formatDate, t, task]);

  const taskIcon = useMemo(() => {
    const userIsAssigned =
      task.taskUsers.some(
        (tu) => tu.isAssigned && tu.teamUserId === currentTeamUser?.id,
      );

    return userIsAssigned ? <UserIcon /> : <TeamIcon />;
  }, [currentTeamUser, task]);

  const isDue = task.dueDate && dayjs(task.dueDate).isBefore(dayjs(), 'day');

  return (
    <div
      className="mb-4 flex flex-col space-y-2 rounded-xl border border-gray-200 p-2"
      key={task.id}
    >
      <div className="flex space-x-2">
        <div className="flex grow space-x-2">
          <Tooltip title={`${t('priority')}: ${t(decapitalize(task.priority))}`}>
            <i className={`priority-${decapitalize(task.priority)}`}>
              <PriorityIcon priority={task.priority} />
            </i>
          </Tooltip>

          <Typography
            className="items-start text-xs"
            startDecorator={(
              <Tooltip title={taskCreatedByTooltip}>
                <i>
                  {taskIcon}
                </i>
              </Tooltip>
            )}
          >
            {getTaskDescription(t, task)}
          </Typography>
        </div>

        <div>
          <Typography
            className={classNames('text-xs', { 'text-red-500': isDue })}
            startDecorator={
              isDue && (
                <Tooltip title={t('dueDateHasBeenPassed')}>
                  <WarningIcon className="text-red-500" />
                </Tooltip>
              )
            }
          >
            {formatDate(task.dueDate)}
          </Typography>
        </div>
      </div>

      <div className="flex items-center justify-between">
        <KpcAvatarGroup
          names={task.taskUsers.filter((tu) => tu.isAssigned).map((a) => a.displayName)}
          size="sm"
        />
        {canEdit && (
          <div className="flex">
            <Tooltip
              arrow
              title={
                task.completedDate
                  ? t('cannotChangeFinishedTask')
                  : t('editType', { type: t('task') })
              }
            >
              <IconButton
                color="primary"
                disabled={!!task.completedDate}
                variant="plain"
                onClick={editToggle.show}
              >
                <EditIcon />
              </IconButton>
            </Tooltip>
            <SnoozeTaskButton task={task} />
            <FinishTaskButton task={task} currentCase={currentCase} />
          </div>
        )}
      </div>
      <AddEditTaskModal toggle={editToggle} initialTask={task} currentCase={currentCase} />
    </div>
  );
};

TaskItem.propTypes = {
  canEdit: PropTypes.bool.isRequired,
  currentCase: CasePropTypes,
  task: TaskPropTypes.isRequired,
};

export default TaskItem;
