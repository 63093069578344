import { FormControl, IconButton, Typography } from '@mui/joy';
import { Bar } from 'react-chartjs-2';
import { Link } from 'react-router-dom';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import Routes from '@/containers/App/Router/Routes';
import { trpc } from '@/config/trpc';
import useDayjs from '@/shared/hooks/useDayjs';

import { InlineSpinner, NewTabIcon, NextIcon, PreviousIcon } from '@/shared/icons/Icons';
import { BaseDatePicker as DatePicker } from '@/shared/components/2.0/DatePicker';

const OpenTasksByTaskTypeChart = () => {
  const { t } = useTranslation();
  const { getWeekOfYear, dayjs } = useDayjs();
  const [date, setDate] = useState(dayjs());

  const { data, isLoading } = trpc.home.tasksByTypeChart.useQuery({
    date: dayjs(date).format('YYYY-MM-DD') as string,
  });

  return (
    <div className="space-y-4 rounded-xl bg-white p-4">
      <FormControl className="flex grow flex-row items-center justify-between space-x-4">
        <Typography
          startDecorator={isLoading && <InlineSpinner />}
          level="h2"
          className="text-xl font-medium"
        >
          {t('week')} {getWeekOfYear(date.toString())}
        </Typography>
        <DatePicker value={date} onChange={(value) => setDate(dayjs(value))} />
        <IconButton onClick={() => setDate(dayjs(date).subtract(1, 'week'))}>
          <PreviousIcon />
        </IconButton>
        <IconButton onClick={() => setDate(dayjs(date).add(1, 'week'))}>
          <NextIcon />
        </IconButton>
        <Link to={Routes.TASKS_OVERVIEW}>
          <IconButton>
            <NewTabIcon />
          </IconButton>
        </Link>
      </FormControl>
      <div className="flex h-96 justify-center">
        <Bar
          options={{
            maintainAspectRatio: false,
            plugins: {
              title: {
                display: true,
                font: { size: 20 },
                text: t('dashboard.chart.openTasksByType'),
              },
            },
            responsive: true,
            scales: { x: { stacked: true }, y: { stacked: true } },
          }}
          data={{
            datasets: data || [],
            labels: [
              dayjs(date).format('DD/MM'),
              dayjs(date).add(1, 'day').format('DD/MM'),
              dayjs(date).add(2, 'day').format('DD/MM'),
              dayjs(date).add(3, 'day').format('DD/MM'),
              dayjs(date).add(4, 'day').format('DD/MM'),
            ],
          }}
        />
      </div>
    </div>
  );
};

export default OpenTasksByTaskTypeChart;
