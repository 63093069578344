import KpcPage from '@/shared/components/layout/KpcPage';
import SalesInvoices from './SalesInvoices/SalesInvoices';

const SyndicManagementOverview: React.FC = () => (
  <KpcPage className="flex flex-grow">
    <SalesInvoices />
  </KpcPage>
);

export default SyndicManagementOverview;
