import fileApi from '@/shared/api/file/file';
import { Nullable } from '~/common/types';

export const toBase64 = (blob: Blob): Promise<string> =>
  new Promise((resolve) => {
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    reader.onloadend = () => {
      const base64data = reader.result as string;
      resolve(base64data.split(',')[1]);
    };
  });

export const filesToBase64 = async (files: File[]) =>
  Promise.all(
    files.map(async (file) => ({
      content: await toBase64(file),
      name: file.name,
    })),
  );

export const downloadBlob = async (blob: Blob, fileName: string): Promise<void> => {
  const link = document.createElement('a');
  link.style.display = 'none';
  document.body.appendChild(link);

  link.href = URL.createObjectURL(blob);
  link.download = fileName;
  link.click();

  URL.revokeObjectURL(link.href);
  document.body.removeChild(link);
};

type FileDownloadParams = {
  id?: string;
  contentType?: string;
  fileName?: string;
  fileUri: string;
  fileBytes?: string;
  extension?: Nullable<string>;
};

export const downloadUsingUri = (fileUri: string, fileName?: string) => {
  if (fileName) {
    fetch(fileUri)
      .then((response) => response.blob())
      .then((blob) => downloadBlob(blob, fileName));

    return;
  }

  const a = document.createElement('a');
  a.href = fileUri;
  a.rel = 'noopener noreferrer';
  a.target = '_blank';

  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
};

export const generateDownloadName = (
  name = 'default_fileName',
  extension: Nullable<string> = null,
) => {
  if (!extension) return name;

  return `${name?.replace(/\..+$/, '')}${extension}`;
};

const downloadFile = async (file: FileDownloadParams): Promise<void> => {
  const { id, fileBytes, fileUri, fileName, contentType, extension } = file;

  if (!id && !fileBytes) {
    downloadUsingUri(fileUri, fileName);
    return;
  }

  try {
    const fileBytesData = fileBytes || (id ? await fileApi.downloadFile(id) : '');
    const binaryString = atob(fileBytesData as string);

    const bytes = new Uint8Array(Array.from(binaryString, (char) => char.charCodeAt(0)));

    const blob = new Blob([bytes], { type: contentType });
    const downloadURL = URL.createObjectURL(blob);
    const link = document.createElement('a');

    link.href = downloadURL;
    link.download = generateDownloadName(fileName, extension);
    link.click();
    link.remove();

    URL.revokeObjectURL(downloadURL);
  } catch (error) {
    downloadUsingUri(file.fileUri, file.fileName);
  }
};

export const getBlobFileName = (contentDisposition: string) => {
  let fileName = 'default_fileName';
  if (contentDisposition) {
    const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
    const matches = filenameRegex.exec(contentDisposition);
    if (matches != null && matches[1]) {
      fileName = matches[1].replace(/['"]/g, '');
    }
  }
  return fileName;
};

export default downloadFile;
