import { useCallback, useState } from 'react';
import { Obj } from '~/common/types';

const useObjState = <TState extends Obj>(initialState: TState) => {
  const [values, setValues] = useState<TState>(initialState);

  const set = useCallback(
    <TKey extends keyof TState>(key: TKey, value: TState[TKey]) =>
      setValues((prevValues) => ({
        ...prevValues,
        [key]: value,
      })),
    [],
  );

  const reset = useCallback(
    <TKey extends keyof TState>(key?: TKey) => {
      if (!key) {
        setValues(initialState);

        return;
      }

      set(key, initialState[key]);
    },
    [initialState, set],
  );

  return {
    ...values,
    reset,
    set,
    setAll: setValues,
  };
};

export default useObjState;
