import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { sortableColumns, tableConfig } from './SalesInvoiceTableConfig';
import { InvoicingInvoiceSortingColumns } from '~/common/enums';
import { OrderSettings } from '~/common/types';
import { pagination } from '~/common/constants';
import QueryInvoicingInvoiceRequest from '~/common/types/finance/invoicingInvoices/invoicingInvoice';
import { SortSettings } from '@/shared/hooks/table/types';
import { trpc } from '@/config/trpc';

import KpcCard from '@/shared/components/2.0/layout/KpcCard';
import PaginatedTable from '@/containers/Finance/Statements/Common/PaginatedTable';
import SalesInvoicesFilters from './SalesInvoicesFilters';

const defaultFilter = {
  amount: null,
  cursor: 1,
  fromDate: null,
  homeownerAssociationId: null,
  invoiceCreationStatus: null,
  invoiceNumber: null,
  orderSettings: {
    defaultSortOrder: false,
    sortColumn: InvoicingInvoiceSortingColumns.InvoiceNumber,
  },
  pageSize: 50,
  untilDate: null,
} as QueryInvoicingInvoiceRequest;

const SalesInvoices: React.FC = () => {
  const [filters, setFilters] = useState(defaultFilter);
  const { t } = useTranslation();
  const [sortSettings, setSortSettings] = useState<SortSettings[]>([filters.orderSettings]);

  useEffect(() => {
    setFilters((prev) => ({
      ...prev,
      orderSettings: sortSettings[0] as OrderSettings<InvoicingInvoiceSortingColumns>,
    }));
  }, [sortSettings]);

  const fetchResult = trpc.finance.invoicingInvoices.get.useInfiniteQuery(filters, {
    getNextPageParam: (lastPage) => lastPage.nextPage,
    initialCursor: pagination.queryParam.FIRST_PAGE,
  });

  const handleSetSearchText = (searchText: string) => {
    if (filters.invoiceNumber === searchText) {
      return;
    }

    setFilters({ ...filters, invoiceNumber: searchText });
  };

  return (
    <KpcCard className="h-100">
      <PaginatedTable
        setSortSettings={setSortSettings}
        filter={<SalesInvoicesFilters filters={filters} setFilters={setFilters} />}
        title={t('_syndicManagement.salesInvoices.title')}
        fetchResult={fetchResult}
        config={tableConfig}
        sortSettings={sortSettings}
        sortableColumns={sortableColumns}
        setSearchText={handleSetSearchText}
        offsetY={228}
      />
    </KpcCard>
  );
};

export default SalesInvoices;
