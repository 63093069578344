import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import CaseStatusType from '~/common/enums/caseStatusType';

import { search as searchConstants } from '../utils/constants';
import useDebounce from './UseDebounce';

const { DELAY_TIME, START_SEARCH_QUERY_LENGTH } = searchConstants;

type AutoCompleteFilterValue = {
  label: string;
  value: string;
};

enum FilterType {
  Autocomplete = 'autocomplete',
  Toggle = 'toggle',
  Number = 'number',
}

export type Filter =
  | {
      name: string;
      type: FilterType.Autocomplete;
      value: AutoCompleteFilterValue[] | AutoCompleteFilterValue | null;
    }
  | {
      name: string;
      type: FilterType.Toggle;
      value: boolean;
    }
  | {
      name: string;
      type: FilterType.Number;
      value: number;
    };

type Filters = {
  [FilterType.Autocomplete]?: {
    [key: string]: AutoCompleteFilterValue[] | AutoCompleteFilterValue | null;
  };
  [FilterType.Toggle]?: {
    [key: string]: boolean;
  };
  [FilterType.Number]?: {
    [key: string]: number;
  };
};

export const useFilters = <T extends Filters>({
  defaultFilters,
  localStorageKey,
}: {
  defaultFilters: T;
  localStorageKey: string;
}) => {
  const saveFilters = useSelector((state) => state.user?.settings?.saveFilters);

  const getStoredFilters = useCallback(
    (filters: T, key: string) => {
      if (localStorageKey) {
        const localStorageData = localStorage.getItem(key);

        if (localStorageData == null) {
          return filters;
        }
        const localStorageFilters = JSON.parse(localStorageData);

        const filteredLocalStorageFilters = {
          ...localStorageFilters,
          autocomplete: {
            ...localStorageFilters.autocomplete,
            statuses:
              localStorageFilters.autocomplete?.statuses?.filter(
                (status) => status.value !== CaseStatusType.Pending,
              ) || [],
          },
        };
        return filteredLocalStorageFilters;
      }
      return filters;
    },
    [localStorageKey],
  );

  const [filters, setFilters] = useState<T>(getStoredFilters(defaultFilters, localStorageKey));

  useEffect(() => {
    if (!saveFilters || !localStorageKey) {
      return;
    }
    localStorage.setItem(localStorageKey, JSON.stringify(filters));
  }, [filters, localStorageKey, saveFilters]);

  const handleChangeFilter = useCallback(({ name, type, value }: Filter) => {
    setFilters((prev) => ({
      ...prev,
      [type]: {
        ...prev[type],
        [name]: value,
      },
    }));
  }, []);

  return {
    changeFilter: handleChangeFilter,
    filters,
    filtersAreSavedInLocalStorage: !!saveFilters && !!localStorageKey,
    removePendingFilter: () =>
      handleChangeFilter({
        name: 'statuses',
        type: FilterType.Autocomplete,
        value: [],
      }),
  };
};

const SEARCH_DEFAULT_OPTIONS = {
  defaultSearch: '',
  startQueryLength: START_SEARCH_QUERY_LENGTH,
};

export const useSearchFilter = (options = {}) => {
  const mergedOptions = { ...SEARCH_DEFAULT_OPTIONS, ...options };

  const [search, setSearch] = useState(mergedOptions.defaultSearch);
  const debounced = useDebounce(search, DELAY_TIME);
  const debounceSearchString = debounced?.length >= mergedOptions.startQueryLength ? debounced : '';

  return {
    debounceSearch: (value: string) => setSearch(value),
    search: debounceSearchString,
    setSearch,
  };
};
