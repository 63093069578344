enum InvoicingInvoiceSortingColumns {
  None = 'None',
  HomeownerAssociationName = 'HomeownerAssociationName',
  InvoiceDate = 'InvoiceDate',
  InvoiceNumber = 'InvoiceNumber',
  VatInclusiveTotalAmount = 'VatInclusiveTotalAmount',
  InvoiceCreationMode = 'InvoiceCreationMode',
}

export default InvoicingInvoiceSortingColumns;
