import { Attributes, createElement, useEffect, useMemo, useRef, useState } from 'react';
import { Tooltip } from '@mui/joy';
import { useTranslation } from 'react-i18next';

import { ColumnProps, HeadCellProps } from '@/shared/hooks/table/types';
import { Obj } from '~/common/types';

type Props = Obj &
  HeadCellProps & {
    type?: string | null;
    column: ColumnProps;
  } & Attributes;

const DefaultHeadCell: React.FC<Props> = (props) => {
  const { t } = useTranslation();
  const { type = null, column } = props;

  const containerRef = useRef<HTMLDivElement | null>(null);
  const [isOverflowing, setIsOverflowing] = useState(false);

  const label = useMemo(() => {
    const { title, key } = column;

    if (!type && !title) return '';

    return title ? t(title) : t(`_${type}.columns.${key}`);
  }, [column, t, type]);

  useEffect(() => {
    const checkOverflow = () => {
      const container = containerRef.current;
      const parent = container?.parentElement;

      if (!parent || !container) {
        setIsOverflowing(false);
        return;
      }

      if (container) {
        setIsOverflowing(container.scrollWidth > parent.clientWidth);
      }
    };

    checkOverflow();

    window.addEventListener('resize', checkOverflow);
    return () => {
      window.removeEventListener('resize', checkOverflow);
    };
  }, []);

  if (column.customHeaderComponent) {
    return createElement(column.customHeaderComponent, props);
  }

  return (
    <div ref={containerRef} className="inline-block pr-2">
      {!isOverflowing ? (
        <div className="inline-block whitespace-nowrap">{label}</div>
      ) : (
        <Tooltip className="whitespace-nowrap" placement="top" title={label}>
          <div className="inline-block">{label}</div>
        </Tooltip>
      )}
    </div>
  );
};

export default {
  DefaultHeadCell,
};
